import { useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../contexts/RootStoreContext";

const GoogleCallback = observer(() => {
  const rootStore = useContext(RootStoreContext)!;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const code = searchParams.get("code");
      if (!code) return;

      try {
        const res = await rootStore.client.get("/session/google/callback/", {
          params: { code },
        });
        rootStore.setAuthToken(res.data.token);
        rootStore.setUser(res.data.user);
        navigate("/");
      } catch (error) {
        console.error("Authentication failed:", error);
      }
    };

    handleAuth();
  }, [searchParams, navigate]);

  return <p>Authenticating...</p>;
});

export default GoogleCallback;
